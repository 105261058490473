import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Layout from './Component/Layout/Layout.jsx';
import ProtactecdRoute from './Component/ProtactecdRoute/ProtactecdRoute.jsx';
import Login from './Component/Login/Login.jsx';
import Chaneels from './Component/Chaneels/Chaneels.jsx';
import Categorys from './Component/Categorys/Categorys.jsx';
import Admins from './Component/Admins/Admins.jsx';
import Profile from './Component/Profile/Profile.jsx';


function App() {
  const [userData, setUserData] = useState(null);

  const saveData = () => {
    setUserData(localStorage.getItem('token'));
  };

  useEffect(() => {

  }, []);

  let routes = createBrowserRouter([
    {
      path: '', element: <Layout userData={userData} setUserData={setUserData} />,
      children: [
        { index: true, element: <ProtactecdRoute><Admins setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'login', element: <Login saveData={saveData} /> },
        { path: 'chaneels', element: <ProtactecdRoute><Chaneels setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'categorys', element: <ProtactecdRoute><Categorys setUserData={setUserData} /> </ProtactecdRoute> },
        { path: 'profile', element: <ProtactecdRoute><Profile setUserData={setUserData} /> </ProtactecdRoute> },


      ]
    }
  ])




  return <>
    <Toaster toastOptions={{
      duration: 3000
    }} />

    <RouterProvider router={routes}>

    </RouterProvider>
  </>
}

export default App;
