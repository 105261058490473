import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios'
import { toast } from "react-hot-toast";
export default function Login({ saveData }) {
    const [messageErr2, setmessageErr2] = useState('')
    const [loading, setloading] = useState(false)
    let navigate = useNavigate()



    async function handleLoginData(values) {
        console.log(values);
        setloading(true)
        let { data } = await axios.patch(`${process.env.REACT_APP_API}/auth/logIn`, values).catch((err) => {
            setmessageErr2(`${err.response.data.message}`)
            setloading(false)
            toast.error(err.response.data.message)
            console.log(err);
        })
        console.log(data);
        if (data.status == "true") {
            localStorage.setItem('token', data.token)
            saveData()
            console.log("from login fun");
            navigate('/')
            setloading(false)
            toast.success(data.message)
        } else {
            toast.error('something wrong')
            setloading(false)
        }


    }

    let validationDataSchema = Yup.object({
        email: Yup.string().required('email is required'),
        password: Yup.string().required('password is required'),

    })

    let formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        }
        ,
        onSubmit: handleLoginData,
        validationSchema: validationDataSchema
    })

    return <>
        <div className='bg-body-tertiary start-0 end-0 top-0 bottom-0   fixed-top row justify-content-center align-content-center'>
            <div className="col-xl-4 col-lg-6 col-md-8 col-10 formRes ">
                <form onSubmit={formik.handleSubmit} className=' w-100 bg-white  p-5 rounded-3 shadow-lg  '>

                    <h2 className="fs-1 text-uppercase fw-bolder   text-center mb-4 text-primary text-capitalize " ><i class="fa-solid fa-stethoscope text-primary fs-1 pe-2"></i>tabibi</h2>
                    {messageErr2 ? <div class="alert alert-danger" role="alert">
                        {messageErr2}
                    </div> : null}

                    <div className="py-1">
                        <label for="email" class="form-label fw-bold text-primary">Email</label>
                        <input className='form-control' type="email" name='email' id='email' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.email && formik.touched.email ? <div className='form-text text-danger'>{formik.errors.email}</div> : null}
                    </div>
                    <div className="py-1">
                        <label for="password" class="form-label fw-bold text-primary">Password</label>
                        <input className='form-control' type="password" name='password' id='password' value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.password && formik.touched.password ? <div className='form-text text-danger'>{formik.errors.password}</div> : null}
                    </div>


                    <div className='row my-2 g-3 px-1'>

                        {loading ? <button type='button' className='btn  mainBtn col-12  rounded-pill '><i className='fa fa-spinner fa-spin'></i></button> :
                            <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn mainBtn col-12 rounded-pill'>Sign in</button>}


                        {/* <button disabled onClick={() => {
                            setLoginMode(false)
                            setForgetMode(true)
                        }} type='button' className='btn  mainBtn2 col-12 rounded-pill'>Forget Password</button> */}




                    </div>



                </form>
            </div>
        </div>

    </>
}
