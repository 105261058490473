import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar.jsx'

export default function Layout({userData,setUserData}) {
    return <>
        <div className='min-vh-100 bg-body-tertiary'>
            <Navbar userData={userData} setUserData={setUserData}></Navbar>
            <Outlet></Outlet>
        </div>
    </>
}
