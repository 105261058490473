import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';

export default function Admins({ setUserData }) {
    const [LoadingAdmins, setLoadingAdmins] = useState(false)
    const [Admins, setAdmins] = useState([])


    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    const [UpdatePass, setUpdatePass] = useState(false)
    const [LoadingUpdatePass, setLoadingUpdatePass] = useState(false)

    //handel gets
    async function getAdmins() {
        setLoadingAdmins(true)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios(`${process.env.REACT_APP_API}/auth/getAdmins`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingAdmins(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingAdmins(false)
            }
        }).then((res) => {
            setAdmins(res?.data?.data)
            setLoadingAdmins(false)

        })
    }


    //handel add 
    let AddvalidationSchema = Yup.object({
        name: Yup.string().required('name is required'),
        email: Yup.string().email().required('email is required'),
        password: Yup.string().required('password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'confirm password must be like password').required('confirmPassword is required'),
        mobileNumber: Yup.string().required('mobileNumber is required')

    })
    let formik2 = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            mobileNumber: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })
    async function handleAdd(values) {
        setLoadingAdd(true);
        let token = localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/auth/createAdmin`, values, { headers });
            console.log(res);
            console.log(res?.data?.data);
            console.log('from then');

            toast.success(res?.data?.message);
            getAdmins();
            setLoadingAdd(false);
            setAddMode(false);
            formik2.resetForm();
        } catch (err) {
            console.log(err);
            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }
            setLoadingAdd(false);
            return toast.error(err?.response?.data?.message);
        }
    }

    //handel update 

    let validationSchema = Yup.object({
        name: Yup.string().required('name is required'),
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            name: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        setLoadingUpdate(true);
        let token = localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };

        try {
            const res = await axios.patch(`${process.env.REACT_APP_API}/auth/updateAccountByAdmin/${values._id}`, values, { headers });
            toast.success(res?.data?.message);
            getAdmins();
            setLoadingUpdate(false);
            setUpdateMode(false);
        } catch (err) {
            console.log(err);

            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }
            setLoadingUpdate(false);
            toast.error(err?.response?.data?.message);
        }
    }

    async function handelUpdatedData(Admin) {
        formik.setValues({
            _id: Admin._id,
            name: Admin.name
        })
        setUpdateMode(true)
    }




    //handel resset password
    let RessetPassValidationSchema = Yup.object({
        password: Yup.string().required('password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'confirm password must be like password').required('confirmPassword is required'),
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            password: "",
            confirmPassword: "",

        },
        onSubmit: handleUpdateAdminPass,
        validationSchema: RessetPassValidationSchema
    })
    async function handleUpdateAdminPass(values) {
        setLoadingUpdatePass(true);
        let token = localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };

        try {
            const res = await axios.patch(`${process.env.REACT_APP_API}/auth/updatePassByAdmin/${values._id}`, values, { headers });
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message);
            setLoadingUpdatePass(false);
            setUpdatePass(false);
            formik3.resetForm();
        } catch (err) {
            console.log(err);
            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }
            setLoadingUpdatePass(false);
            toast.error(err?.response?.data?.message);
        }
    }


    //handel delete 
    async function deleteAdmin(_id) {
        let token = localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };

        await axios.patch(`${process.env.REACT_APP_API}/auth/deleteByAdmin/${_id}`,{}, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getAdmins()
        })
    }

    useEffect(() => {
        getAdmins()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add admin</h3>

                        <label for="name" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='name' id='name' value={formik2.values.name} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.name && formik2.touched.name ? <div className='form-text text-danger'>{formik2.errors.name}</div> : null}


                        <label for="email" class="form-label mainFont mt-2 ">email</label>
                        <input className='form-control' type="email" name='email' id='email' value={formik2.values.email} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.email && formik2.touched.email ? <div className='form-text text-danger'>{formik2.errors.email}</div> : null}


                        <label for="password" class="form-label mainFont mt-2 ">password</label>
                        <input className='form-control' type="password" name='password' id='password' value={formik2.values.password} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.password && formik2.touched.password ? <div className='form-text text-danger'>{formik2.errors.password}</div> : null}

                        <label for="confirmPassword" class="form-label mainFont mt-2 ">confirmPassword</label>
                        <input className='form-control' type="password" name='confirmPassword' id='confirmPassword' value={formik2.values.confirmPassword} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.confirmPassword && formik2.touched.confirmPassword ? <div className='form-text text-danger'>{formik2.errors.confirmPassword}</div> : null}

                        <label for="mobileNumber" class="form-label mainFont mt-2 ">mobileNumber</label>
                        <input className='form-control' type="text" name='mobileNumber' id='mobileNumber' value={formik2.values.mobileNumber} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.mobileNumber && formik2.touched.mobileNumber ? <div className='form-text text-danger'>{formik2.errors.mobileNumber}</div> : null}

                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdatePass ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Reset password admin</h3>

                        <label for="password" class="form-label mainFont mt-2 ">password</label>
                        <input className='form-control' type="password" name='password' id='password' value={formik3.values.password} onChange={formik3.handleChange} onBlur={formik3.handleBlur} />
                        {formik3.errors.password && formik3.touched.password ? <div className='form-text text-danger'>{formik3.errors.password}</div> : null}

                        <label for="confirmPassword" class="form-label mainFont mt-2 ">confirmPassword</label>
                        <input className='form-control' type="password" name='confirmPassword' id='confirmPassword' value={formik3.values.confirmPassword} onChange={formik3.handleChange} onBlur={formik3.handleBlur} />
                        {formik3.errors.confirmPassword && formik3.touched.confirmPassword ? <div className='form-text text-danger'>{formik3.errors.confirmPassword}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdatePass ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>reset</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdatePass(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update admin name</h3>

                        <label for="name" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='name' id='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.name && formik.touched.name ? <div className='form-text text-danger'>{formik.errors.name}</div> : null}

                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Admins</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Admin</button>
                {LoadingAdmins ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Admins?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>Name</th>
                                        <th scope="col" className='mainFont'>Email</th>
                                        <th scope="col" className='mainFont'>Mobile Number</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {Admins?.map((Admin, index) => {
                                        return <tr key={index} className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{Admin.name}</td>
                                            <td className='mainFont'>{Admin.email}</td>
                                            <td className='mainFont'>{Admin.mobileNumber}</td>

                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => {
                                                            deleteAdmin(Admin._id)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => {
                                                            handelUpdatedData(Admin)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => {
                                                            formik3.setFieldValue('_id', Admin._id)
                                                            setUpdatePass(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>resset password</li>

                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Admins</h3>
                    </div>}


                </>
                }
            </div>

        </div>

    </>
}
