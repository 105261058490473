import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom';

export default function Profile({ setUserData }) {
    const navigate = useNavigate();

    const [LoadingProfileData, setLoadingProfileData] = useState(false)
    const [ProfileData, setProfileData] = useState({})


    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

  

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)

    //handel gets
    async function getProfileData() {
        setLoadingProfileData(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `bearer ${token}`
        }

        await axios(`${process.env.REACT_APP_API}/auth/getAccountdata`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingProfileData(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingProfileData(false)
            }
        }).then((res) => {
            console.log(res?.data?.data);

            setProfileData(res?.data?.data)
            setLoadingProfileData(false)

        })
    }


    //handel update pass 
    let AddvalidationSchema = Yup.object({
        password: Yup.string().required('password is required'),
        newPassword: Yup.string().required('newPassword is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'confirm password must be like password').required('confirmPassword is required'),
    })

    let formik2 = useFormik({
        initialValues: {
            password: "",
            newPassword: "",
            confirmPassword: ""
        },
        onSubmit: handleUpdatePass,
        validationSchema: AddvalidationSchema
    })

    async function handleUpdatePass(values) {
        console.log(values);
        
        setLoadingAdd(true);
        let token = localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };
    
        try {
            const res = await axios.patch(`${process.env.REACT_APP_API}/auth/updatePass`,  values , { headers });
            toast.success(res?.data?.message);
            setLoadingAdd(false);
            setAddMode(false);
            formik2.resetForm();
            localStorage.clear();
            setUserData(null);
            navigate('/login')
        } catch (err) {
            console.log(err);
            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }
            setLoadingAdd(false);
            toast.error(err?.response?.data?.message);
        }
    }
    

    //handel update 

    let validationSchema = Yup.object({
        name: Yup.string().required('name is required')
    })

    let formik = useFormik({
        initialValues: {
            name: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/auth/updateAccount`, values, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getProfileData()
            setLoadingUpdate(false)
            setUpdateMode(false)
        })
    }



    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        ProfileImage: Yup.mixed().required('profile Image is required')
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            ProfileImage: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image', values.ProfileImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/auth/updateProfileImage`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getProfileData()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }

    useEffect(() => {
        getProfileData()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add Movie</h3>

                        <label for="password" class="form-label mainFont">password</label>
                        <input className='form-control' type="password" name='password' id='password' value={formik2.values.password} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.password && formik2.touched.password ? <div className='form-text text-danger'>{formik2.errors.password}</div> : null}


                        <label for="newPassword" class="form-label mainFont mt-2 ">New Password</label>
                        <input className='form-control' type="password" name='newPassword' id='newPassword' value={formik2.values.newPassword} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.newPassword && formik2.touched.newPassword ? <div className='form-text text-danger'>{formik2.errors.newPassword}</div> : null}


                        <label for="confirmPassword" class="form-label mainFont mt-2 ">Confirm Password</label>
                        <input className='form-control' type="password" name='confirmPassword' id='confirmPassword' value={formik2.values.confirmPassword} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.confirmPassword && formik2.touched.confirmPassword ? <div className='form-text text-danger'>{formik2.errors.confirmPassword}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update Movie Image</h3>

                        <label for="ProfileImage" class="form-label">Movie Image</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('ProfileImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='ProfileImage'
                            id='ProfileImage'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.ProfileImage && formik3.touched.ProfileImage ? <div className='form-text text-danger'>{formik3.errors.ProfileImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update Movie</h3>

                        <label for="name" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='name' id='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.name && formik.touched.name ? <div className='form-text text-danger'>{formik.errors.name}</div> : null}





                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Profile</h2>
            <div className="row justify-content-center align-items-center">
                <div className='p-5 col-md-4  '>
                    <div className='p-2 w-100 text-center'>
                        <img className='bg-body-secondary p-1 rounded-circle img-fluid shadow-lg fixed-img '  src={ProfileData?.image?.OpenImage} alt="" srcset="" />
                        <div className='row'>

                            <button onClick={() => { setUpdateImageMode(true) }} className='btn mainBtn rounded-pill mt-4 mx-auto col-8   '>Update Image</button>
                        </div>
                    </div>
                </div>
                <div className='p-5 col-md-5  '>
                    <div className='p-2 w-100'>
                        <table class="table table-striped rounded-3 overflow-hidden  mx-auto text-center">
                            <tbody >
                                <tr >
                                    <th scope="col" className='mainFont'>Name</th>
                                    <td scope="col" className='mainFont'>{ProfileData?.name}</td>
                                </tr>
                                <tr >
                                    <th scope="col" className='mainFont'>Email</th>
                                    <td scope="col" className='mainFont'>{ProfileData?.email}</td>
                                </tr>
                                <tr >
                                    <th scope="col" className='mainFont'>Mobile Number</th>
                                    <td scope="col" className='mainFont'>{ProfileData?.mobileNumber}</td>
                                </tr>
                            </tbody>

                        </table>
                        <div className="row">
                            <button onClick={() => {
                                formik.setFieldValue('name', ProfileData?.name)
                                setUpdateMode(true)
                            }} className='btn mainBtn rounded-pill my-2 mx-auto col-md-5  '>Update Name</button>
                            <button onClick={() => {
                                setAddMode(true)
                            }} className='btn mainBtn rounded-pill my-2 mx-auto col-md-5  '>Update Password</button>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>
}
