import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import img from '../../168843.png'
import toast from 'react-hot-toast';

export default function Navbar({ userData, setUserData }) {
    const navigate = useNavigate()


    async function logOut() {
        try {
            let token = localStorage.getItem('token')

            await axios.patch(`${process.env.REACT_APP_API}/auth/logOut`, { Authorization: `Bearer ${token}` }).then((res) => {
                console.log(res);
                localStorage.clear()
                setUserData(null)
                toast.success(res?.data?.message)
                navigate('/login')
            })

        } catch (err) {
            console.log(err);

            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                toast.error(err?.response?.data?.message)
            }
        }

    }

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const navbarRef = useRef(null);

    const handleToggle = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsNavbarOpen(false);
        }
    };

    useEffect(() => {
        if (isNavbarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNavbarOpen]);

    return <>

        <nav className="navbar navbar-expand-xl bg-primary text-white px-5 shadow-lg" ref={navbarRef}>
            < div className="container-lg" >
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <div className="d-flex align-items-center">
                        <i class="fa-solid fa-stethoscope text-white fs-1 pe-2"></i>
                        <span className="navbarLogoSize text-white text-capitalize fw-bolder">tabibi</span>
                    </div>
                </a>

                <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isNavbarOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-lg-0 text-white text-uppercase align-items-center ">

                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to='/'>admins</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to='chaneels'>Departmets</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to='categorys'>Clinics</Link>
                        </li>

                    </ul>
                    <ul className="navbar-nav ms-auto mb-lg-0 text-white text-uppercase align-items-center">
                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to='profile'>profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={logOut} className="nav-link text-white" >Logout</Link>
                        </li>
                    </ul>
                </div>
            </div >
        </nav >

    </>
};

