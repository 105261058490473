import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'


export default function Categorys({ setUserData }) {
    const [LoadingChannels, setLoadingChannels] = useState(false)
    const [Channels, setChannels] = useState([])



    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)


    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)

    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)



    //handel gets
    async function getDoctors() {
        setLoadingChannels(true)
        await axios(`${process.env.REACT_APP_API}/doctorCategory/getDoctorCategory`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingChannels(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingChannels(false)
            }
        }).then((res) => {
            setChannels(res?.data?.data)
            setLoadingChannels(false)

        })
    }


    //handel add 
    let AddvalidationSchema = Yup.object({
        name: Yup.string().required('name is required'),
        CategoryImage: Yup.mixed().required('Category Image is required')

    })

    let formik2 = useFormik({
        initialValues: {
            name: "",
            CategoryImage: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {

        setLoadingAdd(true)
        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('image', values.CategoryImage);

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.post(`${process.env.REACT_APP_API}/doctorCategory/addDoctorCategory`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getDoctors()
            setLoadingAdd(false)
            setAddMode(false)
            formik2.resetForm()
        })
    }
    //handel update 

    let validationSchema = Yup.object({
        name: Yup.string().required('name is required')
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            name: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/doctorCategory/updateDoctorCategory/${values._id}`, {
            name: values.name
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getDoctors()
            setLoadingUpdate(false)
            setUpdateMode(false)


        })
    }

    async function handelUpdatedData(department) {
        formik.setValues({
            _id: department._id,
            name: department.name
        })
        setUpdateMode(true)

    }

    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        CategoryImage: Yup.mixed().required('Category Image is required')
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            CategoryImage: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image', values.CategoryImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/doctorCategory/updateCategoryImage/${values._id}`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getDoctors()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }






    //handel delete 
    async function deleteDeprtment(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/doctorCategory/deleteDoctorCategory/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getDoctors()
        })
    }

    useEffect(() => {
        getDoctors()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add Clinic </h3>

                        <label for="name" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='name' id='name' value={formik2.values.name} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.name && formik2.touched.name ? <div className='form-text text-danger'>{formik2.errors.name}</div> : null}

                        <label for="CategoryImage" class="form-label mainFont mt-1">Channel Image</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('CategoryImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='CategoryImage'
                            id='CategoryImage'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.CategoryImage && formik2.touched.CategoryImage ? <div className='form-text text-danger'>{formik2.errors.CategoryImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update Clinic</h3>
                        <label for="name" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='name' id='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.name && formik.touched.name ? <div className='form-text text-danger'>{formik.errors.name}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update category image</h3>

                        <label for="CategoryImage" class="form-label mainFont mt-2">Channel Image</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('CategoryImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='CategoryImage'
                            id='CategoryImage'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.CategoryImage && formik3.touched.CategoryImage ? <div className='form-text text-danger'>{formik3.errors.CategoryImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Clinics</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Clinic</button>

                {LoadingChannels ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Channels?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>name</th>


                                        <th scope="col" className='mainFont'>Acthions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Channels?.map((channel, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{channel.name}</td>


                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => { deleteDeprtment(channel._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(channel) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => {
                                                            formik3.setFieldValue("_id", channel._id)
                                                            setUpdateImageMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Image</li>



                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Clinics</h3>

                    </div>}


                </>
                }
            </div>

        </div>

    </>
}
